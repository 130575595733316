@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {
    .login-opage-component {
        width: 100% !important;
    }

    .login-opage-component h1 {
        text-align: center;
    }

    /* NAVBAR */
    header {
        height: 80px;
        width: 100%;
        display: flex !important;
        background-color: var(--color-primary);
        align-items: center;
        justify-content: space-between;

        padding: 10px 20px;
    }

    .next-sec-logo {
        width: auto !important;
    }

    .scrollable-ul {
        max-height: 400px !important;
        /* Adjust the height as needed */
        overflow-y: auto !important;
        padding-right: 15px !important;
        /* Optional: Add padding for better look */
        scrollbar-width: thin !important;
        /* For Firefox */
    }

    .scrollable-ul li {
        width: 100%;
        word-wrap: normal;
        white-space: nowrap;

    }

    .liinks-tab-child {
        padding: 5px 10px;
    }

    .border-only--li {
        padding: 5px 1px;
    }

    .scrollable-ul::-webkit-scrollbar {
        width: 8px !important;
        /* Adjust width as needed */
    }

    .scrollable-ul::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
    }

    .scrollable-ul::-webkit-scrollbar-thumb {
        background: #888 !important;
        border-radius: 10px !important;
    }

    .scrollable-ul::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
    }

    .table-wrapper {
        margin: 10px 0px !important;
        box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
    }

    .section--29485 {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column !important;
        /* align-items: start; */
    }

    .toppart-diff {
        padding: 10px 05px;
    }

    .tdFunReq-ButtonAlign {
        flex-wrap: nowrap !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .inputSort-bethistory {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: 10px;
    }

    .toppart-diff.gamjsx {
        display: flex;
        flex-direction: column;
    }

    .baziWrapper {
        padding: 0px 10px !important;
    }

    .section--29485.gameJsx {
        display: flex !important;
        flex-direction: row !important;
        justify-content: start !important;
        gap: 10px;
    }

    .modalContent {
        width: 90% !important;
        max-width: 100% !important;
        /* margin: 10px; */
    }

    .btnResult-ModalDeclare {
        width: 90% !important;
    }

    .singleResultWrap,
    .resultDiv-grid {
        /* background-color: red; */
        width: 100%;
        display: grid;
        grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    }

    .btnResult-ModalDeclare {
        padding: 6px;
        font-size: .6rem;
        font-weight: 600;
        border-radius: 5px;
    }

    .changeStatus {
        min-width: 200px;
        max-width: 300px;
    }

    .section--29485 input {
        width: 70%;
    }

    .table {
        margin-bottom: 50px;
    }

    .liinks-tab-child a {
        font-size: .8rem;
    }

    .btnsNavbar {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}