header {
  height: 80px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: var(--color-primary);
  align-items: center;

  padding: 10px 20px;
}

.next-sec-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.logo-section {
  font-family: poppins;
  font-size: 2rem;
  color: #fff;
}

.links-tabs {
  padding: 10px 30px;
  background-color: var(--color-third);
}

.links-tabs ul {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  color: #fff;
  font-family: poppins;
}

.links-tabs ul li a {
  color: #fff;
}

.border-only--li {
  background-color: #fff;
  width: 2px;
}

.userNameDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: var(--color-third);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
}

.divvStyleLR {
  display: flex;
  gap: 10px;
}

.loginDisplay,
.signupDisplay {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
  font-family: poppins;
  font-weight: 500;
}

.loginDisplay {
  background-color: red;
  color: #fff;
  border: none;
}

.signupDisplay {
  color: red;
  border: 2px solid red;
}

.btnsNavbar {
  display: flex;
  flex-direction: row;
  gap: 10px;
}