.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0px;
    background-color: rgba(128, 128, 128, 0.8);
    z-index: 105;
    /* overflow: hidden; */
}

.modalContent {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 140;
    height: auto;
    overflow: auto;
    scrollbar-width: none;

}

.longHeight {
    height: 100%;
}