.LoginPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: #e5e5f7;
    opacity: 0.8;
    height: 100vh;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 10px), repeating-linear-gradient(#444cf755, #444cf7);
}

.login-opage-component {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #c0c0c0;
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
}

.login-opage-component h1 {
    font-family: poppins;
    color: var(--color-secondary);
}

.login-opage-component form {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.login-opage-component form div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.login-opage-component form div label {
    text-align: start;
    width: 80%;
    font-weight: 600;
    font-family: poppins;
    margin-top: 20px;
}

.login-opage-component form div input {
    width: 80%;
    font-family: poppins;
    font-size: 1rem;
    padding: 10px;
    border: 1px solid #c0c0c0;
    border-radius: 15px;
}

.loginButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background-color: var(--color-secondary);
    color: #fff;
    font-family: 1.2em;
    border-radius: 10px;
    margin-top: 20px;
}

